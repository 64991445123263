<template>
  <div>
    <div class="lg:flex lg:space-x-10">
      <div class="w-full space-y-7">
        <div class="card p-3">
          <form class="mt-4" v-on:submit.prevent="mergePeople">
            <div class="grid grid-cols-2 gap-3 lg:p-6 p-4">
              <div class="col-span-2">
                <label for="link_keep" class="font-semibold">Link nghệ sĩ muốn giữ (*)</label>
                <input required v-model="link_keep" placeholder="" class="shadow-none with-border" id="link_keep" type="text" autocomplete="off">
              </div>
              <div class="col-span-2">
                <label for="link_delete" class="font-semibold">Link nghệ sĩ muốn xóa (*)</label>
                <input required v-model="link_delete" placeholder="" class="shadow-none with-border" id="link_delete" type="text" autocomplete="off">
              </div>
              <div class="col-span-2">
                <button type="submit"
                      class="text-white hover:text-white hover:bg-green-700 bg-green-600 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-4 py-2.5 text-center inline-flex items-center">Gộp nghệ sĩ
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ApiService from "../../../core/services/api.service";
import {changePageTitle, isAdmin} from "../../../core/services/utils.service";

export default {
  name: "MergePeople",
  data() {
    return {
      link_keep: "",
      link_delete: ""
    }
  },
  methods: {
    mergePeople() {
      let query = `mutation($link_keep: String!, $link_delete: String!) {
        mergePeople(link_keep: $link_keep, link_delete: $link_delete) {
          id
        }
      }`;

      ApiService.graphql(query, {link_keep: this.link_keep, link_delete: this.link_delete})
        .then(({data}) => {
          if (data.data && data.data.mergePeople) {
            this.link_keep = "";
            this.link_delete = "";
            this.$toast.success("Gộp nghệ sĩ thành công");
          } else if(data.errors) {
            this.$toast.error("Gộp nghệ sĩ không thành công: " + data.errors[0].message);
          }
        })
        .catch((response) => {
          this.$toast.error("Gộp nghệ sĩ không thành công: " + response.message);
        });
    }
  },
  mounted() {
    if (!isAdmin) {
      this.$router.push({name: "Home"});
      return;
    }
    changePageTitle("Gộp nghệ sĩ");
  },
  computed: {
    isAdmin() {
      return isAdmin();
    }
  }
}
</script>
